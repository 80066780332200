<messages>["../BookEntry"]</messages>
<template>
  <v-combobox
    v-if="!isRegistry"
    v-model="payee"
    clearable
    :return-object="false"
    :disabled="readonly"
    :items="fields"
    :label="$t ('create.payee')"/>
  <registry-select
    v-else
    v-model="internalRegistry"
    required
    :error-messages="errorMessages"
    clearable
    :label="$t ('create.payee')"/>
</template>

<script>
  import RegistrySelect from '@/app/core/components/RegistrySelect.vue'
  import {external, icann, registry} from './BookEntryForm.vue'

  const coreassoc = 'generic:coreassoc'
  const coreDefault = 'generic:corehub'

  export default {
    components: {RegistrySelect},
    props: {
      value: {
        type: String,
        default: null
      },
      type: {
        type: String,
        default: 'standard'
      },
      errorMessages: {
        type: Array,
        default: () => []
      }
    },

    data () {
      return {internalRegistry: null}
    },

    computed: {
      payee: {
        get () {
          return this.value
        },

        set (newVal) {
          this.$emit ('input', newVal)
        }
      },

      isRegistry () {
        return this.type === registry
      },

      fields () {
        return [
          {
            text: 'COREhub (default)',
            value: coreDefault
          },
          {
            text: 'CORE Association',
            value: coreassoc
          }
        ]
      },

      readonly () {
        return this.type === external || this.type === icann
      }
    },

    watch: {
      internalRegistry (v) {
        this.payee = v ? `registry:${v}` : null
      }
    },

    created () {
      this.payee = (this.isRegistry ? null : this.value || coreDefault)
    }

  }
</script>
