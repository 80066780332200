var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.$t('create.section.basic'))+" ")])]),(_vm.showClientSelect)?_c('v-col',{attrs:{"cols":"12","md":"12","lg":"4"}},[_c('client-select',{attrs:{"nullable":"","required":_vm.isStandard,"for-view":"","v":_vm.$v.bookEntry.clientId,"v-messages":{
              required: _vm.$t ('create.errors.clientIdRequired'),
              notAllowed: _vm.$t ('create.errors.noAllowed')
            }},model:{value:(_vm.bookEntry.clientId),callback:function ($$v) {_vm.$set(_vm.bookEntry, "clientId", $$v)},expression:"bookEntry.clientId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[(!_vm.clientTax || _vm.isIcann || _vm.isExternal)?_c('v-text-field',{staticClass:"required",attrs:{"step":"0.01","label":_vm.$t ('create.accountChange'),"error-messages":_vm.validationErrors(
              'bookEntry.accountChange',
              {
                required: 'create.errors.changeRequired',
                decimal: 'create.errors.decimal',
                nonzero: 'create.errors.nonzero'
              })},model:{value:(_vm.bookEntry.accountChange),callback:function ($$v) {_vm.$set(_vm.bookEntry, "accountChange", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"bookEntry.accountChange"}}):_c('v-text-field',{staticClass:"required",attrs:{"step":"0.01","label":_vm.$t ('create.netAmount'),"error-messages":_vm.validationErrors(
              'bookEntry.netAmount',
              {
                required: 'create.errors.changeRequired',
                decimal: 'create.errors.decimal',
                nonzero: 'create.errors.nonzero'
              })},model:{value:(_vm.bookEntry.netAmount),callback:function ($$v) {_vm.$set(_vm.bookEntry, "netAmount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"bookEntry.netAmount"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('currency-select',{staticClass:"required",attrs:{"label":_vm.$t ('create.currency'),"error-messages":_vm.validationErrors(
              'bookEntry.currency',
              {
                required: 'create.errors.currencyRequired'
              })},model:{value:(_vm.bookEntry.currency),callback:function ($$v) {_vm.$set(_vm.bookEntry, "currency", $$v)},expression:"bookEntry.currency"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":_vm.showClientSelect ? 3 : 7}},[_c('v-select',{staticClass:"required",attrs:{"label":_vm.$t('create.actionType'),"items":_vm.actionTypes,"disabled":_vm.actionTypes.length <=1,"error-messages":_vm.validationErrors(
              'bookEntry.actionType',
              {
                required: 'create.errors.actionRequired',
                invalidOption: 'create.errors.invalidAction'
              })},model:{value:(_vm.bookEntry.actionType),callback:function ($$v) {_vm.$set(_vm.bookEntry, "actionType", $$v)},expression:"bookEntry.actionType"}})],1),(_vm.showAutoConversion)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{attrs:{"label":_vm.$t('create.autoConvert')},model:{value:(_vm.bookEntry.autoConvert),callback:function ($$v) {_vm.$set(_vm.bookEntry, "autoConvert", $$v)},expression:"bookEntry.autoConvert"}})],1):_vm._e(),(_vm.bookEntry.autoConvert && _vm.showAutoConversion)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('auto-conversion-info',{attrs:{"client-id":_vm.bookEntry.clientId,"src-currency":_vm.bookEntry.currency,"src-amount":_vm.clientTax ? _vm.bookEntry.netAmount : _vm.bookEntry.accountChange}})],1):_vm._e(),(_vm.showObjectInfo)?[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.$t('create.section.object'))+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"label":_vm.$t('create.objectType'),"clearable":"","items":_vm.objectTypes,"error-messages":_vm.validationErrors(
                'bookEntry.objectType',
                {
                  required: 'create.errors.objectTypeRequired',
                  notAllowed: _vm.$t ('create.errors.noAllowed')
                })},model:{value:(_vm.bookEntry.objectType),callback:function ($$v) {_vm.$set(_vm.bookEntry, "objectType", $$v)},expression:"bookEntry.objectType"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t ('create.objectId'),"error-messages":_vm.validationErrors(
                'bookEntry.objectId',
                {
                  required: 'create.errors.objectIdRequired',
                  notAllowed: _vm.$t ('create.errors.noAllowed')
                })},model:{value:(_vm.bookEntry.objectId),callback:function ($$v) {_vm.$set(_vm.bookEntry, "objectId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"bookEntry.objectId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('registry-select',{attrs:{"label":_vm.$t ('create.registryId'),"clearable":"","error-messages":_vm.validationErrors(
                'bookEntry.registryId',
                {
                  required: 'create.errors.registryIdRequired',
                  notAllowed: _vm.$t ('create.errors.noAllowed')
                })},model:{value:(_vm.bookEntry.registryId),callback:function ($$v) {_vm.$set(_vm.bookEntry, "registryId", $$v)},expression:"bookEntry.registryId"}})],1)]:_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.$t('create.section.additional'))+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t ('create.subject')},model:{value:(_vm.bookEntry.subject),callback:function ($$v) {_vm.$set(_vm.bookEntry, "subject", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"bookEntry.subject"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t ('create.comment')},model:{value:(_vm.bookEntry.comment),callback:function ($$v) {_vm.$set(_vm.bookEntry, "comment", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"bookEntry.comment"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t ('create.refersTo'),"error-messages":_vm.validationErrors(
              'bookEntry.refersTo',
              {
                numeric: 'create.errors.number'
              })},model:{value:(_vm.bookEntry.refersTo),callback:function ($$v) {_vm.$set(_vm.bookEntry, "refersTo", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"bookEntry.refersTo"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('payee-field',{attrs:{"error-messages":_vm.validationErrors(
              'bookEntry.payee',
              {
                required: 'create.errors.payeeRequired',
              }),"type":_vm.type},model:{value:(_vm.bookEntry.payee),callback:function ($$v) {_vm.$set(_vm.bookEntry, "payee", $$v)},expression:"bookEntry.payee"}})],1),(_vm.showClientTax)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-switch',{attrs:{"label":_vm.isRegistry ? _vm.$t('create.useRegistryTax') : _vm.$t('create.useClientTax')},model:{value:(_vm.clientTax),callback:function ($$v) {_vm.clientTax=$$v},expression:"clientTax"}})],1):_vm._e(),(!_vm.clientTax && _vm.showClientTax)?[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"type":"number","step":"0.01","label":_vm.$t ('create.netAmount'),"error-messages":_vm.validationErrors(
                'bookEntry.netAmount',
                {
                  decimal: 'create.errors.decimal',
                  nonzero: 'create.errors.nonzero',
                  notAllowed: _vm.$t ('create.errors.noAllowed')
                })},model:{value:(_vm.bookEntry.netAmount),callback:function ($$v) {_vm.$set(_vm.bookEntry, "netAmount", $$v)},expression:"bookEntry.netAmount"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"error-messages":_vm.validationErrors(
                'bookEntry.taxName',
                {
                  notAllowed: _vm.$t ('create.errors.noAllowed')
                }),"label":_vm.$t ('create.taxName')},model:{value:(_vm.bookEntry.taxName),callback:function ($$v) {_vm.$set(_vm.bookEntry, "taxName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"bookEntry.taxName"}})],1)]:_vm._e()],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.$t ('general.button.reset'))+" ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.isLoading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t ('general.button.submit'))+" ")])],1)],1),(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_c('pre',{staticClass:"inlineCode"},[_vm._v("        "+_vm._s(_vm.errorMsg)+"\n      ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }