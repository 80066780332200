<messages>["../BookEntry"]</messages>

<template>
  <v-col cols="12">
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div class="subheading">
              {{ $t('create.section.basic') }}
            </div>
          </v-col>
          <v-col v-if="showClientSelect" cols="12" md="12" lg="4">
            <client-select
              v-model="bookEntry.clientId"
              nullable
              :required="isStandard"
              for-view
              :v="$v.bookEntry.clientId"
              :v-messages="{
                required: $t ('create.errors.clientIdRequired'),
                notAllowed: $t ('create.errors.noAllowed')
              }"/>
          </v-col>
          <v-col cols="12" md="2" lg="2">
            <v-text-field
              v-if="!clientTax || isIcann || isExternal"
              v-model.trim="bookEntry.accountChange"
              class="required"
              step="0.01"
              :label="$t ('create.accountChange')"
              :error-messages="validationErrors(
                'bookEntry.accountChange',
                {
                  required: 'create.errors.changeRequired',
                  decimal: 'create.errors.decimal',
                  nonzero: 'create.errors.nonzero'
                })"/>
            <v-text-field
              v-else
              v-model.trim="bookEntry.netAmount"
              class="required"
              step="0.01"
              :label="$t ('create.netAmount')"
              :error-messages="validationErrors(
                'bookEntry.netAmount',
                {
                  required: 'create.errors.changeRequired',
                  decimal: 'create.errors.decimal',
                  nonzero: 'create.errors.nonzero'
                })"/>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <currency-select
              v-model="bookEntry.currency"
              class="required"
              :label="$t ('create.currency')"
              :error-messages="validationErrors(
                'bookEntry.currency',
                {
                  required: 'create.errors.currencyRequired'
                })"/>
          </v-col>
          <v-col cols="12" md="6" :lg="showClientSelect ? 3 : 7">
            <v-select
              v-model="bookEntry.actionType"
              class="required"
              :label="$t('create.actionType')"
              :items="actionTypes"
              :disabled="actionTypes.length <=1"
              :error-messages="validationErrors(
                'bookEntry.actionType',
                {
                  required: 'create.errors.actionRequired',
                  invalidOption: 'create.errors.invalidAction'
                })"/>
          </v-col>

          <v-col v-if="showAutoConversion" cols="12" sm="6">
            <v-switch
              v-model="bookEntry.autoConvert"
              :label="$t('create.autoConvert')"/>
          </v-col>
          <v-col v-if="bookEntry.autoConvert && showAutoConversion" cols="12" sm="6">
            <auto-conversion-info
              :client-id="bookEntry.clientId"
              :src-currency="bookEntry.currency"
              :src-amount="clientTax ? bookEntry.netAmount : bookEntry.accountChange"/>
          </v-col>

          <template v-if="showObjectInfo">
            <v-col cols="12">
              <div class="subheading">
                {{ $t('create.section.object') }}
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="bookEntry.objectType"
                :label="$t('create.objectType')"
                clearable
                :items="objectTypes"
                :error-messages="validationErrors(
                  'bookEntry.objectType',
                  {
                    required: 'create.errors.objectTypeRequired',
                    notAllowed: $t ('create.errors.noAllowed')
                  })"/>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model.trim="bookEntry.objectId"
                :label="$t ('create.objectId')"
                :error-messages="validationErrors(
                  'bookEntry.objectId',
                  {
                    required: 'create.errors.objectIdRequired',
                    notAllowed: $t ('create.errors.noAllowed')
                  })"/>
            </v-col>
            <v-col cols="12" sm="4">
              <registry-select
                v-model="bookEntry.registryId"
                :label="$t ('create.registryId')"
                clearable
                :error-messages="validationErrors(
                  'bookEntry.registryId',
                  {
                    required: 'create.errors.registryIdRequired',
                    notAllowed: $t ('create.errors.noAllowed')
                  })"/>
            </v-col>
          </template>

          <v-col cols="12">
            <div class="subheading">
              {{ $t('create.section.additional') }}
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model.trim="bookEntry.subject"
              :label="$t ('create.subject')"/>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model.trim="bookEntry.comment"
              :label="$t ('create.comment')"/>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model.trim="bookEntry.refersTo"
              :label="$t ('create.refersTo')"
              :error-messages="validationErrors(
                'bookEntry.refersTo',
                {
                  numeric: 'create.errors.number'
                })"/>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <payee-field
              v-model="bookEntry.payee"
              :error-messages="validationErrors(
                'bookEntry.payee',
                {
                  required: 'create.errors.payeeRequired',
                })"
              :type="type"/>
          </v-col>

          <v-col v-if="showClientTax" cols="12" sm="4">
            <v-switch v-model="clientTax" :label="isRegistry ? $t('create.useRegistryTax') : $t('create.useClientTax')"/>
          </v-col>
          <template v-if="!clientTax && showClientTax">
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="bookEntry.netAmount"
                type="number"
                step="0.01"
                :label="$t ('create.netAmount')"
                :error-messages="validationErrors(
                  'bookEntry.netAmount',
                  {
                    decimal: 'create.errors.decimal',
                    nonzero: 'create.errors.nonzero',
                    notAllowed: $t ('create.errors.noAllowed')
                  })"/>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model.trim="bookEntry.taxName"
                :error-messages="validationErrors(
                  'bookEntry.taxName',
                  {
                    notAllowed: $t ('create.errors.noAllowed')
                  })"
                :label="$t ('create.taxName')"/>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="reset">
          {{ $t ('general.button.reset') }}
        </v-btn>
        <v-btn color="primary" :loading="isLoading" @click="submit">
          {{ $t ('general.button.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-alert v-if="error" type="error">
      <pre class="inlineCode">
          {{ errorMsg }}
        </pre>
    </v-alert>
  </v-col>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex'

  import {required, decimal, requiredIf, numeric} from 'vuelidate/lib/validators'

  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import ClientSelect from '@/app/core/components/ClientSelect'
  import RegistrySelect from '@/app/core/components/RegistrySelect'
  import CurrencySelect from '@/app/core/components/CurrencySelect'

  import AutoConversionInfo from './AutoConversionInfo'
  import PayeeField from './PayeeField'

  const baseBookEntry = () => ({
    clientId: null,
    objectType: null,
    objectId: null,
    registryId: null,
    actionType: 'generic',
    subject: null,
    refersTo: null,
    comment: null,
    currency: 'USD',
    accountChange: '',
    netAmount: null,
    taxName: null,
    autoConvert: true,
    payee: null
  })

  const objectTypes = [
    'contact',
    'domain',
    'email',
    'host',
    'protection',
    'watch',
    'application'
  ]

  const DefaultActionTypes = [
    'modify',
    'new',
    'refund-create-failure',
    'refund-delete',
    'refund-renew',
    'refund-transfer',
    'renew-auto',
    'renew-manual',
    'transfer-in',
    'redemption',
    'refund',
    'ns-usage',
    'refund-ns-usage',
    'generic',
    'unlock',
    'membership'
  ]
  export const standard = 'standard'
  export const icann = 'icann'
  export const registry = 'registry'
  export const external = 'external'

  const actionTypes = {
    [standard]: DefaultActionTypes,
    [icann]: ['icann-fees'],
    [registry]: DefaultActionTypes,
    [external]: [
      'escrow',
      'registrar-insurance',
      'whois-operation',
      'frontend-support'
    ]
  }

  export default {
    components: {
      ClientSelect,
      CurrencySelect,
      RegistrySelect,
      AutoConversionInfo,
      PayeeField
    },

    mixins: [validationMixins],

    props: {
      type: {
        type: String,
        default: 'standard'
      }
    },

    validations: {
      bookEntry: {
        clientId: {
          required: requiredIf (function () {
            return this.isStandard
          }),
          notAllowed: function (v) {
            return !this.isExternal || !v
          }
        },
        objectType: {
          required: requiredIf (function () {
            return !!this.bookEntry.objectId && !this.isExternal
          }),
          notAllowed: function (v) {
            return !this.isExternal || !v
          }
        },
        objectId: {
          required: requiredIf (function () {
            return !!this.bookEntry.objectType && !this.isExternal
          }),
          notAllowed: function (v) {
            return !this.isExternal || !v
          }
        },
        registryId: {
          required: requiredIf (function () {
            return !!this.bookEntry.objectId && !this.isExternal
          }),
          notAllowed: function (v) {
            return !this.isExternal || !v
          }
        },
        actionType: {
          required,
          invalidOption: function (v) {
            return actionTypes[this.type].includes (v)
          }
        },
        accountChange: {
          required: requiredIf (function () {
            return (!this.isStandard && !this.isRegistry) || !this.clientTax
          }),
          decimal,
          nonzero: v => Number.parseFloat (v) !== 0
        },
        currency: {required},
        netAmount: {
          decimal,
          required: requiredIf (function () {
            return this.clientTax && (this.isStandard || this.isRegistry)
          }),
          nonzero: v => Number.parseFloat (v) !== 0
        },
        taxName: {
          notAllowed: function (v) {
            return this.isStandard || this.isRegistry || !v
          }
        },
        refersTo: {
          numeric
        },
        autoConvert: {
          notAllowed: function (v) {
            return this.isStandard || !v
          }
        },
        payee: {
          required: requiredIf (function () {
            return this.isRegistry
          }),
          invalid: function (v) {
            switch (this.type) {
              case icann:
                return 'generic:icann'
              case external:
                return 'generic:external'
              case registry:
              case standard:
                return true
            }
          }
        }
      }
    },

    data () {
      return {
        bookEntry: baseBookEntry (),
        isLoading: false,
        showClientTax: true,
        showAutoConversion: true,
        showClientSelect: true,
        showObjectInfo: true,
        payeeEditable: true,
        clientTax: true,
        error: null,
        syncNet: false
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'actingClientId'
      ]),

      isStandard () {
        return this.type === standard
      },
      isIcann () {
        return this.type === icann
      },
      isRegistry () {
        return this.type === registry
      },
      isExternal () {
        return this.type === external
      },

      objectTypes () {
        return objectTypes.map ((t) => ({
          value: t,
          text: this.$t (`create.objectTypes.${t}`)
        }))
      },

      actionTypes () {
        return actionTypes[this.type].map ((t) => ({
          value: t,
          text: this.$t (`create.actionTypes.${t.replace (/-/g, '')}`)
        }))
      },

      errorMsg () {
        if (!this.error) { return null }

        let msg = ''

        Object.keys (this.error).forEach ((key) => {
          const errors = this.error[key].reduce ((acc, e) => {
            return acc === '' ? e : `${acc}, ${e}`
          }, '')

          msg = `${msg}\n${key}: ${errors}`
        })

        return msg
      }
    },

    watch: {
      type () {
        this.setDefaults ()
      },
      clientTax (newVal) {
        if (newVal) {
          this.bookEntry.accountChange = ''
        }
      },
      'bookEntry.accountChange': function (newVal) {
        if (this.syncNet) {
          this.bookEntry.netAmount = newVal
        }
      }
    },

    created () {
      this.bookEntry.clientId = this.actingClientId
      this.setDefaults ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      ...mapMutations ({
        displayErrorMessage: 'notification/setError',
        displaySuccessMessage: 'notification/setSuccess'
      }),

      reset () {
        const resistedClientId = this.bookEntry.clientId
        this.bookEntry = baseBookEntry ()
        this.setDefaults ()
        if (!this.isExternal) { this.bookEntry.clientId = resistedClientId }
      },

      setDefaults () {
        switch (this.type) {
          case standard:
            this.showClientTax = true
            this.showAutoConversion = true
            this.showClientSelect = true
            this.showObjectInfo = true
            this.payeeEditable = true
            this.clientTax = true
            this.syncNet = false
            this.bookEntry = {
              ...this.bookEntry,
              clientId: this.actingClientId,
              actionType: 'generic'
            }
            break
          case icann:
            this.showClientTax = false
            this.showAutoConversion = false
            this.showClientSelect = true
            this.showObjectInfo = false
            this.payeeEditable = false
            this.clientTax = false
            this.syncNet = false
            this.bookEntry = {
              ...this.bookEntry,
              clientId: null,
              actionType: 'icann-fees',
              autoConvert: false,
              payee: 'generic:icann'
            }
            break
          case registry:
            this.showClientTax = true
            this.showAutoConversion = false
            this.showClientSelect = true
            this.showObjectInfo = true
            this.payeeEditable = true
            this.clientTax = true
            this.syncNet = false
            this.bookEntry = {
              ...this.bookEntry,
              clientId: null,
              actionType: 'generic',
              autoConvert: false,
              payee: null
            }
            break
          case external:
            this.showClientTax = false
            this.showAutoConversion = false
            this.showClientSelect = false
            this.showObjectInfo = false
            this.payeeEditable = false
            this.clientTax = false
            this.syncNet = true
            this.bookEntry = {
              ...this.bookEntry,
              clientId: null,
              actionType: 'escrow',
              autoConvert: false,
              payee: 'generic:external'
            }
        }
      },

      submit () {
        this.error = null

        if (!this.$v.$invalid) {
          this.isLoading = true
          this.fetchData ({
            op: 'account/create-book-entry',
            params: {
              data: this.bookEntry
            },
            cb: (d) => {
              if (d.data) {
                this.error = d.data
                this.displayErrorMessage (this.$t ('create.bookEntryError'))
              } else {
                this.reset ()
                this.displaySuccessMessage (this.$t ('create.bookEntrySuccess'))
              }
            },
            cbFinal: () => {
              this.isLoading = false
              this.$v.$reset ()
            }
          })
        } else {
          this.$v.$touch ()
        }
      }
    }
  }
</script>
