<messages>["../BookEntry"]</messages>

<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-text-field
        :value="calculatedAmount"
        disabled
        reverse
        :label="$t ('label.destAmount')"
        :loading="isLoading"/>
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
        :value="rate"
        disabled
        reverse
        :label="$t ('label.rate')"
        :loading="isLoading"/>
    </v-col>
  </v-row>
</template>

<script>
  import _debounce from 'lodash/debounce'
  import {mapActions} from 'vuex'

  import {EmptyMark} from '@/app/utils/string'

  export default {
    props: {
      srcAmount: {
        type: [String, Number],
        default: undefined
      },

      srcCurrency: {
        type: String,
        default: ''
      },

      clientId: {
        type: Number,
        default: undefined
      }
    },

    data () {
      return {
        isLoading: false,
        info: null
      }
    },

    computed: {
      isValid () {
        return this.srcAmount && !isNaN (this.srcAmount) && this.srcCurrency && this.clientId
      },
      calculatedAmount () {
        return this.info ? this.formatMoneyAmount (this.info.amount, this.info.destCurrency) : EmptyMark
      },
      rate () {
        return this.info ? this.info.rate : EmptyMark
      }
    },

    watch: {
      clientId () {
        this.loadConverstion ()
      },
      srcCurrency () {
        this.loadConverstion ()
      },
      srcAmount () {
        this.loadConverstion ()
      }
    },

    created () {
      this.loadConverstion ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadConverstion: _debounce (function () {
        if (this.isValid && !this.isLoading) {
          this.isLoading = true

          this.fetchData ({
            op: 'client/data/client-currency-conversion',
            params: {
              id: this.clientId,
              srcCurrency: this.srcCurrency,
              srcAmount: this.srcAmount
            },
            cb: (d) => {
              console.log (d)
              this.info = d.info
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        } else if (!this.isValid) {
          this.info = null
        }
      }, 500)
    }
  }
</script>
