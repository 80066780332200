<messages>["./BookEntry"]</messages>

<template>
  <base-layout mw1>
    <v-card>
      <v-card-title primary-title>
        <div>
          <div class="text-h5">
            {{ $t ('create.title') }}
          </div>
          <div class="cgwng-subheading">
            {{ $t ('create.subTitle') }}
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-tabs
          v-model="tab">
          <v-tab v-for="f in forms" :key="f">
            {{ $t(`create.${f}`) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="f in forms" :key="f">
            <book-entry-form :type="f"/>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </base-layout>
</template>

<script>
  import BaseLayout from '@/app/core/components/BaseLayout'

  import BookEntryForm, {external, icann, registry, standard} from './components/BookEntryForm'

  export default {
    components: {
      BaseLayout,
      BookEntryForm
    },

    data () {
      return {
        tab: 0,
        forms: [standard, icann, registry, external]
      }
    }
  }
</script>
